/*

general styling

font size:
24px title
16px text
14px button

button mobile 

padding: 10px 30px;


*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

/*====================
media queries
======================*/

@media (max-width: 575.98px) {
  /*=======================
    language, section, images
  =========================*/
  .navigationText {
    bottom: 1%;
    right: 50%;
    user-select: none;

    h1 {
      text-align: center;
      font-size: 14px;
      color: $textPrimary;
      font-family: roboto-medium;
      user-select: none;
    }

    .imageWrapper {
      transform: translate(0px, -3px);
      text-align: center;
      img {
        user-select: none;
        width: 16px;
        margin: 0 auto;
        fill: $textHighlight;
        animation-name: scrollanim;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .languageSelector {
    position: absolute;
    transform: translate(0%, 50%);
    bottom: 50%;
    right: 5px;
    z-index: 300;

    h1 {
      text-align: center;
      font-size: 14px;
      color: $textPrimary;
      font-family: roboto-medium;
      margin-bottom: 2px;
      margin-top: 5px;
      user-select: none;
    }
  }

  .textBorderLeft {
    display: none;
  }

  .textBorderRight {
    display: none;
  }

  .imageHide {
    display: none;
  }

  /*=======================
    ozon
  =========================*/

  .contentRow1 {
    padding-left: 0px;
    position: relative;
    transform: translate(0%);
    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        margin-bottom: 5%;
        text-align: center;
        font-size: 20px;
        span {
          color: $textHighlight;
        }
      }
      p {
        font-family: inter-extra;
        font-size: 14px;
        color: $textStandard;
        text-align: center;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 30px;
        font-family: roboto-medium;
        font-size: 14px;
        color: $backgroundColor;
        user-select: none;
        transition: 0.3s;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow2 {
    z-index: 100;
    transform: translate(0%);
    padding-right: 0px;
    position: relative;
    .leftSide {
      transform: translate(50px);
      user-select: none;
    }

    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        text-align: center;
        font-size: 20px;
      }

      span {
        color: $textHighlight;
      }

      p {
        font-family: inter-extra;
        font-size: 14px;
        color: $textStandard;
        text-align: center;
        width: 80%;
        margin: 0 auto;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 30px;
        font-family: roboto-medium;
        font-size: 14px;
        color: $backgroundColor;
        transition: 0.3s;
        user-select: none;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow5 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  /*=======================
    sagos
  =========================*/

  .sagosRow1 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosSection2LangNav {
    display: none;
  }

  .sagosRow2 {
    .rightSide {
      h1 {
        font-size: 14px;
      }
    }

    .regionColumn {
      .scrollIconLeft {
        font-size: 16px;
      }

      .scrollIconRight {
        font-size: 16px;
      }
    }

    .regionWrapper {
      .regionPaddingLeft {
        width: 40px;
      }

      .regionPaddingRight {
        width: 50px;
      }

      .regionCard {
        padding-top: 2px;
        padding-bottom: 2px;
        p {
          font-size: 12px;
        }
      }
    }

    /*=======================
    card
  =========================*/

    .searchMember {
      flex-direction: column;
      .searchBox {
        flex-direction: column;
      }
    }

    .cardSection {
      z-index: 10;
      .memberCard {
        width: 60%;
      }

      .memberCardDescription {
        text-align: center;

        h3 {
          font-size: 18px;
        }

        h4 {
          line-height: 14px;
          font-size: 16px;
        }

        p {
          line-height: 10px;
          font-size: 12px;
          margin-bottom: 0px;
        }

        .city {
          font-size: 10px;
          line-height: 10px;
          margin: 4px 0px;
        }
      }

      .memberCardStatus {
        p {
          font-size: 12px;
          padding: 5px;
          margin-bottom: 0px;
        }
      }

      .memberCardButtons {
        .memberCardLeftButton {
          border-right: none;
          a {
            font-size: 12px;
            padding: 5px;
          }
        }
        .memberCardRightButton {
          p {
            font-size: 12px;
            padding: 5px;
          }
        }

        .memberCardIconLeft {
          font-size: 12px;
          transform: translate(1px);
        }

        .memberCardIconRight {
          font-size: 12px;
          transform: translate(1px);
        }
      }

      .memberCard {
        z-index: 100;
      }

      .cardScrollLeft {
        z-index: 300;
        .cardLeftIcon {
          z-index: 300;
          font-size: 40px;
        }
      }

      .cardScrollRight {
        z-index: 300;
        .cardRightIcon {
          z-index: 300;
          font-size: 40px;
        }
      }
    }
  }

  .membersRow2 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow3 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow4 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow5 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow6 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  /*=======================
    members
  =========================*/

  .membersRow1 {
    transform: translate(0%);
    padding-left: 0px;
    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
        margin-bottom: 3%;
      }

      h4 {
        font-size: 16px;
      }

      input {
        padding: 5px 10px;
        border: 2px solid $textPrimary;
        text-align: center;
      }

      .showPassword {
        font-size: 14px;
      }

      button {
        padding: 10px 40px;
        font-size: 14px;
      }
      .buttonCenter {
        text-align: center;
      }

      .afterLogin {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .wrongPasswordVisible {
    font-size: 14px !important;
    visibility: hidden;
  }

  .wrongPasswordInvisible {
    font-size: 14px !important;
    visibility: hidden;
  }

  .membersRow3 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .membersRow4 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .membersRow5 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }
    }
  }

  /*=======================
    navbar
  =========================*/
  .navbar-brand {
    display: inherit;
  }

  .navbar-brand img {
    width: 150px;
    height: 30px;
  }

  .navbar {
    padding-right: 16px !important;
  }

  .activeLink {
    font-size: 14px;
    transition: 0.3s;
    text-align: center;
    padding: 10px 10px !important;
  }

  .inactiveLink {
    font-size: 14px;
    padding: 10px 10px !important;
    text-align: center;
  }

  /*=======================
    modal
  =========================*/

  .modalRight {
    margin-top: 20px;
  }

  .modalHeader {
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 0px;
    h2 {
      font-family: inter-regular;
      font-size: 20px;
      color: $textPrimary;
    }
  }

  .modalCloseButton {
    position: absolute;
    color: $textStandard;
    top: 10px;
    right: 15px;
    font-size: 20px;
    transition: 0.3s;
    user-select: none;
  }

  .modalCloseButton:hover {
    cursor: pointer;
    color: rgb(46, 46, 46);
    transition: 0.3s;
    user-select: none;
  }

  .modalRow {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .modalLeft {
    a {
      padding: 5px;
    }
    .modalLeftItem {
      font-size: 12px;
      text-align: left;
    }
  }
  .modalRightTab {
    padding-right: 40px;
    h6 {
      font-size: 18px;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 16px !important;
    }
    p {
      font-size: 14px;
    }

    a {
      font-size: 14px;
    }
  }

  /*=======================
    fullpage nav bar
  =========================*/

  #fp-nav {
    left: 0px !important;
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  /*=======================
    language, section, images
  =========================*/
  .navigationText {
    position: absolute;
    bottom: 1%;
    right: 50%;
    transform: translate(50%);
    user-select: none;

    h1 {
      text-align: center;
      font-size: 14px;
      color: $textPrimary;
      font-family: roboto-medium;
      user-select: none;
    }

    .imageWrapper {
      transform: translate(0px, -3px);
      text-align: center;
      img {
        user-select: none;
        width: 16px;
        margin: 0 auto;
        fill: $textHighlight !important;
        animation-name: scrollanim;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .languageSelector {
    position: absolute;
    transform: translate(0%, 50%);
    bottom: 50%;
    right: 5px;
    z-index: 300;

    h1 {
      text-align: center;
      font-size: 14px;
      color: $textPrimary;
      font-family: roboto-medium;
      margin-bottom: 2px;
      margin-top: 5px;
      user-select: none;
    }
  }

  .textBorderLeft {
    display: none;
  }

  .textBorderRight {
    display: none;
  }

  .imageHide {
    display: none;
  }

  /*=======================
    ozon
  =========================*/

  .contentRow1 {
    padding-left: 0px;
    position: relative;
    transform: translate(0%);
    visibility: hidden;
    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        margin-bottom: 5%;
        text-align: center;
        font-size: 20px;
        span {
          color: $textHighlight;
        }
      }
      p {
        font-family: inter-extra;
        font-size: 14px;
        color: $textStandard;
        text-align: center;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 30px;
        font-family: roboto-medium;
        font-size: 14px;
        color: $backgroundColor;
        user-select: none;
        transition: 0.3s;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow2 {
    z-index: 100;
    transform: translate(0%);
    padding-right: 0px;
    position: relative;
    .leftSide {
      transform: translate(50px);
      user-select: none;
    }

    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        text-align: center;
        font-size: 20px;
      }

      span {
        color: $textHighlight;
      }

      p {
        font-family: inter-extra;
        font-size: 14px;
        color: $textStandard;
        text-align: center;
        width: 80%;
        margin: 0 auto;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 30px;
        font-family: roboto-medium;
        font-size: 14px;
        color: $backgroundColor;
        transition: 0.3s;
        user-select: none;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow3 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .contentRow4 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .contentRow5 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .contentRow6 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }
    }
  }

  /*=======================
    sagos
  =========================*/

  .sagosRow1 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosSection2LangNav {
    display: none;
  }

  .sagosRow2 {
    .rightSide {
      h1 {
        font-size: 14px;
      }
    }

    .regionColumn {
      .scrollIconLeft {
        font-size: 16px;
      }

      .scrollIconRight {
        font-size: 16px;
      }
    }

    .regionWrapper {
      .regionPaddingLeft {
        width: 40px;
      }

      .regionPaddingRight {
        width: 50px;
      }

      .regionCard {
        padding-top: 2px;
        padding-bottom: 2px;
        p {
          font-size: 12px;
        }
      }
    }

    /*=======================
    card
  =========================*/

    .cardSection {
      z-index: 10 !important;
      .memberCard {
        width: 50%;
      }

      .memberCardDescription {
        text-align: center;

        h3 {
          font-size: 18px;
        }

        h4 {
          line-height: 14px;
          font-size: 16px;
        }

        p {
          line-height: 10px;
          font-size: 12px;
          margin-bottom: 0px;
        }
        .city {
          font-size: 10px;
          line-height: 10px;
          margin: 4px 0px;
        }
      }

      .memberCardStatus {
        p {
          font-size: 12px;
          padding: 5px;
          margin-bottom: 0px !important;
        }
      }

      .memberCardButtons {
        .memberCardLeftButton {
          border-right: none;
          a {
            font-size: 12px;
            padding: 5px;
          }
        }
        .memberCardRightButton {
          p {
            font-size: 12px;
            padding: 5px;
          }
        }

        .memberCardIconLeft {
          font-size: 12px;
          transform: translate(1px);
        }

        .memberCardIconRight {
          font-size: 12px;
          transform: translate(1px);
        }
      }

      .memberCard {
        z-index: 100 !important;
      }

      .cardScrollLeft {
        z-index: 300 !important;
        .cardLeftIcon {
          z-index: 300 !important;
          font-size: 40px;
        }
      }

      .cardScrollRight {
        z-index: 300 !important;
        .cardRightIcon {
          z-index: 300 !important;
          font-size: 40px;
        }
      }
    }
  }

  .membersRow2 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow3 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow4 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow5 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .sagosRow6 {
    padding-left: 0px;
    transform: translate(0%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  /*=======================
    members
  =========================*/

  .membersRow1 {
    transform: translate(0%);
    padding-left: 0px;
    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
        margin-bottom: 3%;
      }

      h4 {
        font-size: 16px;
      }

      input {
        padding: 5px 10px;
        border: 2px solid $textPrimary;
        text-align: center;
      }

      .showPassword {
        font-size: 14px;
      }

      button {
        padding: 10px 40px;
        font-size: 14px;
      }
      .buttonCenter {
        text-align: center;
      }

      .afterLogin {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .wrongPasswordVisible {
    font-size: 14px !important;
    visibility: hidden;
  }

  .wrongPasswordInvisible {
    visibility: hidden;
    font-size: 14px !important;
  }

  .membersRow3 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .membersRow4 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 14px;
      }
    }
  }

  .membersRow5 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0 auto;
        width: 80%;
      }
    }
  }

  /*=======================
    navbar
  =========================*/
  .navbar-brand {
    display: inherit;
  }

  .navbar-brand img {
    width: 150px;
    height: 30px;
  }

  .navbar {
    padding-right: 16px !important;
  }

  .activeLink {
    font-size: 14px;
    transition: 0.3s;
    text-align: center;
    padding: 10px 10px !important;
  }

  .inactiveLink {
    font-size: 14px;
    padding: 10px 10px !important;
    text-align: center;
  }

  /*=======================
    modal
  =========================*/

  .modalRight {
    margin-top: 20px;
  }

  .modalHeader {
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 0px;
    h2 {
      font-family: inter-regular;
      font-size: 20px;
      color: $textPrimary;
    }
  }

  .modalCloseButton {
    position: absolute;
    color: $textStandard;
    top: 10px;
    right: 15px;
    font-size: 20px;
    transition: 0.3s;
    user-select: none;
  }

  .modalCloseButton:hover {
    cursor: pointer;
    color: rgb(46, 46, 46);
    transition: 0.3s;
    user-select: none;
  }

  .modalRow {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .modalLeft {
    a {
      padding: 5px;
    }
    .modalLeftItem {
      font-size: 12px;
      text-align: left;
    }
  }
  .modalRightTab {
    padding-right: 40px;
    h6 {
      font-size: 18px;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 16px !important;
    }
    p {
      font-size: 14px;
    }

    a {
      font-size: 14px;
    }
  }

  /*=======================
    fullpage nav bar
  =========================*/

  .sectionBackground {
    background-color: $backgroundColor;
  }

  #fp-nav {
    left: 0px !important;
    a span {
      background-color: $textPrimary !important;
      width: 6px !important;
      height: 6px !important;
    }

    a {
      padding: 5px;
    }

    .active span {
      background-color: $textHighlight !important;
      width: 14px !important;
      height: 14px !important;
    }

    .fp-tooltip.fp-left {
      color: $textPrimary !important;
      font-family: roboto-medium;
      padding-left: 3px;
      font-size: 12px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  /*=======================
    language, section, images
  =========================*/
  .navigationText {
    position: absolute;
    bottom: 1%;
    right: 50%;
    transform: translate(50%);
    user-select: none;

    h1 {
      text-align: center;
      font-size: 12px;
      color: $textPrimary;
      font-family: roboto-medium;
      user-select: none;
    }

    .imageWrapper {
      transform: translate(0px, -3px);
      text-align: center;
      img {
        user-select: none;
        width: 14px;
        margin: 0 auto;
        fill: $textHighlight !important;
        animation-name: scrollanim;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .languageSelector {
    position: absolute;
    transform: translate(0%, 50%);
    bottom: 50%;
    right: 5px;
    z-index: 300;

    h1 {
      text-align: center;
      font-size: 12px;
      color: $textPrimary;
      font-family: roboto-medium;
      margin-bottom: 2px;
      margin-top: 5px;
      user-select: none;
    }
  }

  .textBorderLeft {
    display: inherit;
  }

  .textBorderRight {
    display: inherit;
  }

  .imageHide {
    display: inherit;
  }

  /*=======================
    ozon
  =========================*/

  .contentRow1 {
    padding-left: 0px;
    position: relative;
    transform: translate(5%);
    visibility: hidden;
    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        margin-bottom: 5%;
        text-align: center;
        font-size: 18px;
        span {
          color: $textHighlight;
        }
      }
      p {
        font-family: inter-extra;
        font-size: 12px;
        color: $textStandard;
        text-align: center;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 30px;
        font-family: roboto-medium;
        font-size: 12px;
        color: $backgroundColor;
        user-select: none;
        transition: 0.3s;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow2 {
    z-index: 100;
    transform: translate(-5%);
    padding-right: 0px;
    position: relative;
    .leftSide {
      transform: translate(-5px);
      user-select: none;
    }

    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        text-align: center;
        font-size: 18px;
      }

      span {
        color: $textHighlight;
      }

      p {
        font-family: inter-extra;
        font-size: 12px;
        color: $textStandard;
        text-align: center;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 30px;
        font-family: roboto-medium;
        font-size: 12px;
        color: $backgroundColor;
        transition: 0.3s;
        user-select: none;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow3 {
    transform: translate(5%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .contentRow4 {
    transform: translate(-5%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .contentRow5 {
    transform: translate(10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .contentRow6 {
    transform: translate(-5%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  /*=======================
    sagos
  =========================*/

  .sagosRow1 {
    padding-left: 0px;
    transform: translate(10%);

    .rightSide {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .sagosSection2LangNav {
    display: inherit;
  }

  .sagosRow2 {
    .rightSide {
      h1 {
        font-size: 20px;
      }
    }

    .regionColumn {
      .scrollIconLeft {
        font-size: 16px;
      }

      .scrollIconRight {
        font-size: 16px;
      }
    }

    .regionWrapper {
      .regionPaddingLeft {
        width: 40px;
      }

      .regionPaddingRight {
        width: 50px;
      }

      .regionCard {
        padding-top: 2px;
        padding-bottom: 2px;
        p {
          font-size: 14px;
        }
      }
    }

    /*=======================
    card
  =========================*/

    .cardSection {
      z-index: 10 !important;
      .memberCard {
        width: 70% !important;
      }

      .memberCardDescription {
        text-align: center;

        h3 {
          font-size: 16px;
        }

        h4 {
          line-height: 14px;
          font-size: 14px;
        }

        p {
          line-height: 10px;
          font-size: 12px;
          margin-bottom: 0px;
        }
        .city {
          font-size: 10px;
          line-height: 10px;
          margin: 4px 0px;
        }
      }

      .memberCardStatus {
        p {
          font-size: 12px;
          padding: 5px;
          margin-bottom: 0px !important;
        }
      }

      .memberCardButtons {
        .memberCardLeftButton {
          border-right: none;
          a {
            font-size: 10px;
            padding: 5px;
          }
        }
        .memberCardRightButton {
          p {
            padding: 5px;
            font-size: 10px;
            transform: translate(-10px);
          }
        }

        .memberCardIconLeft {
          display: none;
        }

        .memberCardIconRight {
          display: none;
        }
      }

      .memberCard {
        z-index: 100 !important;
      }

      .cardScrollLeft {
        z-index: 300 !important;
        .cardLeftIcon {
          z-index: 300 !important;
          font-size: 40px;
        }
      }

      .cardScrollRight {
        z-index: 300 !important;
        .cardRightIcon {
          z-index: 300 !important;
          font-size: 40px;
        }
      }
    }
  }

  .membersRow2 {
    padding-left: 0px;
    transform: translate(10%);

    .rightSide {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .sagosRow3 {
    padding-left: 0px;
    transform: translate(-5%);

    .rightSide {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .sagosRow4 {
    padding-left: 0px;
    transform: translate(5%);

    .rightSide {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .sagosRow5 {
    padding-left: 0px;
    transform: translate(-5%);

    .rightSide {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .sagosRow6 {
    padding-left: 0px;
    transform: translate(5%);

    .rightSide {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
    }
  }

  /*=======================
    members
  =========================*/

  .membersRow1 {
    transform: translate(0%);
    padding-left: 0px;
    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
        margin-bottom: 3%;
      }

      h4 {
        font-size: 12px;
      }

      input {
        padding: 2px 5px;
        border: 2px solid $textPrimary;
        text-align: center;
      }

      .showPassword {
        font-size: 12px;
      }

      button {
        padding: 10px 40px;
        font-size: 12px;
      }
      .buttonCenter {
        text-align: center;
      }

      .afterLogin {
        p {
          font-size: 12px;
        }
      }
    }
  }

  .wrongPasswordVisible {
    font-size: 12px !important;
  }

  .wrongPasswordInvisible {
    font-size: 12px !important;
  }

  .membersRow3 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .membersRow4 {
    transform: translate(0%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }

      button {
        margin-top: 5%;
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }

  .membersRow5 {
    transform: translate(5%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  /*=======================
    navbar
  =========================*/
  .navbar-brand {
    display: none;
  }

  .navbar {
    padding-right: 16px !important;
  }

  .activeLink {
    font-size: 16px;
    transition: 0.3s;
    text-align: center;
    padding: 10px 10px !important;
  }

  .inactiveLink {
    font-size: 16px;
    padding: 10px 10px !important;
    text-align: center;
  }

  /*=======================
    modal
  =========================*/

  .modalRight {
    margin-top: 0px;
  }

  .modalHeader {
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 0px;
    h2 {
      font-family: inter-regular;
      font-size: 18px;
      color: $textPrimary;
    }
  }

  .modalCloseButton {
    position: absolute;
    color: $textStandard;
    top: 10px;
    right: 15px;
    font-size: 18px;
    transition: 0.3s;
    user-select: none;
  }

  .modalCloseButton:hover {
    cursor: pointer;
    color: rgb(46, 46, 46);
    transition: 0.3s;
    user-select: none;
  }

  .modalRow {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .modalLeft {
    a {
      padding: 5px;
    }
    .modalLeftItem {
      font-size: 10px;
      text-align: left;
    }
  }
  .modalRightTab {
    padding-right: 40px;
    h6 {
      font-size: 16px;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 14px !important;
    }
    p {
      font-size: 12px;
    }

    a {
      font-size: 12px;
    }
  }

  /*=======================
    fullpage nav bar
  =========================*/

  .sectionBackground {
    background-color: $backgroundColor;
  }

  #fp-nav {
    left: 0px !important;
    a span {
      background-color: $textPrimary !important;
      width: 6px !important;
      height: 6px !important;
    }

    a {
      padding: 5px;
    }

    .active span {
      background-color: $textHighlight !important;
      width: 14px !important;
      height: 14px !important;
    }

    .fp-tooltip.fp-left {
      color: $textPrimary !important;
      font-family: roboto-medium;
      padding-left: 1px;
      font-size: 12px;
      display: none;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  /*=======================
    language, section, images
  =========================*/
  .navigationText {
    position: absolute;
    bottom: 1%;
    right: 50%;
    transform: translate(50%);
    user-select: none;

    h1 {
      text-align: center;
      font-size: 14px;
      color: $textPrimary;
      font-family: roboto-medium;
      user-select: none;
    }

    .imageWrapper {
      transform: translate(0px, -3px);
      text-align: center;
      img {
        user-select: none;
        width: 14px;
        margin: 0 auto;
        fill: $textHighlight !important;
        animation-name: scrollanim;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .languageSelector {
    position: absolute;
    transform: translate(0%, 50%);
    bottom: 50%;
    right: 5px;
    z-index: 300;

    h1 {
      text-align: center;
      font-size: 14px;
      color: $textPrimary;
      font-family: roboto-medium;
      margin-bottom: 2px;
      margin-top: 5px;
      user-select: none;
    }
  }

  .textBorderLeft {
    display: inherit;
  }

  .textBorderRight {
    display: inherit;
  }

  .imageHide {
    display: inherit;
  }

  /*=======================
    ozon
  =========================*/

  .contentRow1 {
    padding-left: 0px;
    position: relative;
    transform: translate(10%);
    visibility: hidden;
    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        margin-bottom: 5%;
        text-align: center;
        font-size: 20px;
        span {
          color: $textHighlight;
        }
      }
      p {
        font-family: inter-extra;
        font-size: 14px;
        color: $textStandard;
        text-align: center;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 40px;
        font-family: roboto-medium;
        font-size: 14px;
        color: $backgroundColor;
        user-select: none;
        transition: 0.3s;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow2 {
    z-index: 100;
    transform: translate(-10%);
    padding-right: 0px;
    position: relative;
    .leftSide {
      transform: translate(-5px);
      user-select: none;
    }

    .rightSide {
      h1 {
        color: $textPrimary;
        font-family: roboto-medium;
        text-align: center;
        font-size: 20px;
      }

      span {
        color: $textHighlight;
      }

      p {
        font-family: inter-extra;
        font-size: 14px;
        color: $textStandard;
        text-align: center;
      }

      button {
        margin-top: 5%;
        background-color: $textHighlight;
        border: none;
        padding: 15px 40px;
        font-family: roboto-medium;
        font-size: 14px;
        color: $backgroundColor;
        transition: 0.3s;
        user-select: none;
      }

      .buttonCenter {
        text-align: center;
      }

      button:hover {
        color: $textPrimary;
        transition: 0.3s;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .contentRow3 {
    transform: translate(10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .contentRow4 {
    transform: translate(-10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .contentRow5 {
    transform: translate(10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .contentRow6 {
    transform: translate(-10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  /*=======================
    sagos
  =========================*/

  .sagosRow1 {
    padding-left: 0px;
    transform: translate(15%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .sagosSection2LangNav {
    display: inherit;
  }

  .sagosRow2 {
    .rightSide {
      h1 {
        font-size: 24px;
      }
    }

    .regionColumn {
      .scrollIconLeft {
        font-size: 16px;
      }

      .scrollIconRight {
        font-size: 16px;
      }
    }

    .regionWrapper {
      .regionPaddingLeft {
        width: 40px;
      }

      .regionPaddingRight {
        width: 50px;
      }

      .regionCard {
        padding-top: 2px;
        padding-bottom: 2px;
        p {
          font-size: 14px;
        }
      }
    }

    /*=======================
    card
  =========================*/

    .cardSection {
      z-index: 10 !important;
      .memberCard {
        width: 70% !important;
      }

      .memberCardDescription {
        text-align: center;

        h3 {
          font-size: 18px;
        }

        h4 {
          line-height: 14px;
          font-size: 16px;
        }

        p {
          line-height: 10px;
          font-size: 12px;
          margin-bottom: 0px;
        }
        .city {
          font-size: 10px;
          line-height: 10px;
          margin: 4px 0px;
        }
      }

      .memberCardStatus {
        p {
          font-size: 12px;
          padding: 5px;
          margin-bottom: 0px !important;
        }
      }

      .memberCardButtons {
        .memberCardLeftButton {
          border-right: 2px solid #e6e6e6;

          a {
            font-size: 12px;
            padding: 5px;
          }
        }
        .memberCardRightButton {
          p {
            padding: 5px;
            font-size: 12px;
            transform: translate(-10px);
          }
        }

        .memberCardIconLeft {
          display: none;
        }

        .memberCardIconRight {
          display: none;
        }
      }

      .memberCard {
        z-index: 100 !important;
      }

      .cardScrollLeft {
        z-index: 300 !important;
        .cardLeftIcon {
          z-index: 300 !important;
          font-size: 40px;
        }
      }

      .cardScrollRight {
        z-index: 300 !important;
        .cardRightIcon {
          z-index: 300 !important;
          font-size: 40px;
        }
      }
    }
  }

  .membersRow2 {
    padding-left: 0px;
    transform: translate(10%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .sagosRow3 {
    padding-left: 0px;
    transform: translate(-10%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .sagosRow4 {
    padding-left: 0px;
    transform: translate(10%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .sagosRow5 {
    padding-left: 0px;
    transform: translate(-10%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .sagosRow6 {
    padding-left: 0px;
    transform: translate(10%);

    .rightSide {
      h1 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
  }

  /*=======================
    members
  =========================*/

  .membersRow1 {
    transform: translate(5%);
    padding-left: 0px;
    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin-bottom: 3%;
      }

      h4 {
        font-size: 14px;
      }

      input {
        padding: 2px 5px;
        border: 2px solid $textPrimary;
        text-align: center;
      }

      .showPassword {
        font-size: 12px;
      }

      button {
        padding: 10px 40px;
        font-size: 14px;
      }
      .buttonCenter {
        text-align: center;
      }

      .afterLogin {
        p {
          font-size: 12px;
        }
      }
    }
  }

  .wrongPasswordVisible {
    font-size: 14px !important;
  }

  .wrongPasswordInvisible {
    font-size: 14px !important;
  }

  .membersRow3 {
    transform: translate(10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .membersRow4 {
    transform: translate(-10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      button {
        margin-top: 5%;
        padding: 15px 40px;
        font-size: 14px;
      }
    }
  }

  .membersRow5 {
    transform: translate(10%);
    padding-left: 0px;

    .rightSide {
      h1 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  /*=======================
    navbar
  =========================*/
  .navbar-brand {
    display: inherit;
  }

  .activeLink {
    font-size: 14px;
    transition: 0.3s;
    text-align: center;
    padding: 10px 10px !important;
  }

  .inactiveLink {
    font-size: 14px;
    padding: 10px 10px !important;
    text-align: center;
  }

  /*=======================
    modal
  =========================*/

  .modalRight {
    margin-top: 0px;
  }

  .modalHeader {
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 0px;
    h2 {
      font-family: inter-regular;
      font-size: 20px;
      color: $textPrimary;
    }
  }

  .modalCloseButton {
    position: absolute;
    color: $textStandard;
    top: 10px;
    right: 15px;
    font-size: 20px;
    transition: 0.3s;
    user-select: none;
  }

  .modalCloseButton:hover {
    cursor: pointer;
    color: rgb(46, 46, 46);
    transition: 0.3s;
    user-select: none;
  }

  .modalRow {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .modalLeft {
    a {
      padding: 5px;
    }
    .modalLeftItem {
      font-size: 12px;
      text-align: left;
    }
  }
  .modalRightTab {
    padding-right: 40px;
    h6 {
      font-size: 18px;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 16px !important;
    }
    p {
      font-size: 14px;
    }

    a {
      font-size: 14px;
    }
  }

  /*=======================
    fullpage nav bar
  =========================*/

  .sectionBackground {
    background-color: $backgroundColor;
  }

  #fp-nav {
    left: 0px !important;
    a span {
      background-color: $textPrimary !important;
      width: 6px !important;
      height: 6px !important;
    }

    a {
      padding: 5px;
    }

    .active span {
      background-color: $textHighlight !important;
      width: 14px !important;
      height: 14px !important;
    }

    .fp-tooltip.fp-left {
      color: $textPrimary !important;
      font-family: roboto-medium;
      padding-left: 1px;
      font-size: 14px;
      display: inherit;
    }
  }
}
