/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.sagosCardWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sagosRow2 {
  position: relative;
  z-index: 100;

  .rightSide {
    h1 {
      color: $textPrimary;
      font-family: roboto-medium;
      text-align: left;
      font-size: 30px;
      user-select: none;
    }

    span {
      color: $textHighlight;
    }
  }

  .searchMember {
    display: flex;
    .searchBox {
      display: flex;
      flex-direction: row;
      padding: 5px;
      margin-bottom: 5px;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin: 0px 5px;
        user-select: none;
      }
      input {
        height: 100%;
        border: 1px solid lightgray;
        border-radius: 2px;
      }
    }
    .pdfBox {
      display: flex;
      flex-direction: row;
      padding: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      .pdfIcon {
        color: $textPrimary;
        height: 14px;
        align-self: center;
      }
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin: 0px 5px;
        text-decoration: underline;
        color: $textPrimary;
      }
    }
  }
  .regionColumn {
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #0000001a;

    .scrollLeft {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
      z-index: 1000;
      background-color: rgba(243, 243, 243, 1);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      user-select: none;
    }

    .scrollRight {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      z-index: 1000;
      background-color: rgba(243, 243, 243, 1);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      user-select: none;
    }

    .scrollLeft:hover {
      cursor: pointer;

      .scrollIconLeft {
        color: $textHighlight;
        transition: 0.3s;
      }
    }

    .scrollRight:hover {
      cursor: pointer;

      .scrollIconRight {
        color: $textHighlight;
        transition: 0.3s;
      }
    }

    .scrollIconLeft {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      transition: 0.3s;
      color: #00000041;
    }

    .scrollIconRight {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      transition: 0.3s;
      color: #00000041;
    }
  }

  .regionWrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;

    .regionPaddingLeft {
      display: inline-block;
      width: 30px;
      height: 100%;
    }

    .regionPaddingRight {
      display: inline-block;
      width: 50px;
      height: 100%;
    }

    .regionCard {
      display: inline-block;
      margin-left: 1%;
      padding-top: 5px;
      padding-bottom: 5px;
      p {
        text-align: center;
        font-family: inter-extra;
        font-size: 16px;
        color: $textStandard;
        margin: 0px;
        padding: 2px 5px !important;
        transition: 0.3s;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    .regionCard:hover {
      cursor: pointer;

      .activeRegionButton {
        color: $backgroundColor !important;
      }

      .inactiveRegionButton {
        color: $textHighlight !important;
      }
    }

    .activeRegionButton {
      background-color: $textHighlight;
      color: $backgroundColor !important;
      border-radius: 10px !important;
      transition: 0.3s;
    }

    .inactiveRegionButton {
      transition: 0.3s;
      color: $backgroundColor !important;
      border-radius: 10px !important;
      color: $textStandard !important;
    }
  }

  .regionWrapper {
    -webkit-overflow-scrolling: touch;
  }

  .regionWrapper {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  /**************************
card
***************************/

  .cardWrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .cardWrapper::-webkit-scrollbar {
    height: 6px !important;
  }

  .cardWrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    transition: 0.3s;
  }

  .cardWrapper::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    border-radius: 50px;
  }

  .cardWrapper::-webkit-scrollbar-thumb:hover {
    background: rgb(151, 151, 151);
    transition: 0.3s;
  }

  .cardSection {
    margin-top: 1%;

    .memberCard {
      background-color: #ffffff;

      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #0000001a;
      display: inline-block;
      margin-left: 20px;
      overflow: hidden;
    }

    .memberCardImage {
      text-align: center;
      user-select: none;
      img {
        margin: 5% 0%;
        height: 120px;
        border-radius: 5px;
      }
    }

    .memberCardDescription {
      text-align: center;

      h3 {
        font-family: inter-bold;
        color: $textPrimary;
        overflow: hidden;
        font-size: 24px;
      }

      h4 {
        font-family: inter-regular;
        color: $textPrimary;
        overflow: hidden;
        line-height: 20px;
        font-size: 20px;
      }

      span {
        color: $textHighlight;
      }

      p {
        line-height: 10px;
        font-family: inter-extra;
        color: $textStandard;
        font-size: 16px;
      }
      .city {
        font-size: 14px;
        line-height: 4px;
      }
    }

    .memberCardStatus {
      text-align: center;
      padding-top: 2px;
      padding-bottom: 10px;
      p {
        padding: 10px 10px;
        width: 60%;
        margin: 0 auto;
        margin-bottom: 5% !important;
        background-color: #dcf3ff;
        color: $textPrimary;
        font-family: inter-extra;
        font-size: 16px;
        box-shadow: 0px 3px 6px #0000001a;
      }
    }

    .memberCardButtons {
      .memberCardLeftButton {
        text-align: center;
        border-top: 2px solid #e6e6e6;
        border-right: 2px solid #e6e6e6;
        a {
          display: inline-block;
          font-family: inter-extra;
          color: $textStandard;
          font-size: 14px;
          text-decoration: none;
          transition: 0.3s;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        a:hover {
          transition: 0.3s;
        }
      }
      .memberCardRightButton {
        text-align: center;
        border-top: 2px solid #e6e6e6;
        transition: 0.3s;
        p {
          display: inline-block;
          font-family: inter-extra;
          color: $textStandard;
          font-size: 14px;
          transition: 0.3s;
          margin-bottom: 0px !important;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        p:hover {
          transition: 0.3s;
        }
      }

      .memberCardRightButton:hover {
        transition: 0.3s;

        .memberCardIconRight {
          color: $textHighlight;
          transition: 0.3s;
        }
      }

      .memberCardLeftButton:hover {
        .memberCardIconLeft {
          color: $textHighlight;
          transition: 0.3s;
        }
      }

      .memberCardIconLeft {
        display: inline-block;
        color: #d1d1d1;
        font-size: 16px;
        transition: 0.3s;
        transform: translate(-5px, 0px);
      }

      .memberCardIconRight {
        display: inline-block;
        color: #d1d1d1;
        font-size: 16px;
        transition: 0.3s;
        transform: translate(-5px, 0px);
      }
    }
  }

  .cardScrollLeft {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    z-index: 1000;
    transition: 0.3s;

    .cardLeftIcon {
      position: absolute;
      font-size: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $textHighlight;
      cursor: pointer;
      background-color: white;
      border-radius: 50px;
      border: 2px solid white;
      user-select: none;
      box-shadow: 0px 3px 6px #0000003d;
    }
  }

  .cardScrollRight {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100%;
    z-index: 1000;
    transition: 0.3s;

    .cardRightIcon {
      position: absolute;
      font-size: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $textHighlight;
      cursor: pointer;
      background-color: white;
      border-radius: 50px;
      border: 2px solid white;
      user-select: none;
      box-shadow: 0px 3px 6px #0000003d;
    }
  }
}

@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}
