/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.membersRow3 {
  transform: translate(10%);
  padding-left: 10px;
  position: relative;
  z-index: 100;
  visibility: hidden;
  .rightSide {
    h1 {
      color: $textPrimary;
      font-family: roboto-medium;
      text-align: center;
      font-size: 30px;
      margin-bottom: 5%;
    }

    span {
      color: $textHighlight;
    }

    p {
      font-family: inter-extra;
      font-size: 16px;
      color: $textStandard;
      text-align: center;
    }

    button {
      margin-top: 2%;
      background-color: $textHighlight;
      border: none;
      padding: 15px 40px;
      font-family: roboto-medium;
      font-size: 16px;
      color: $backgroundColor;
      transition: 0.3s;
      user-select: none;
    }

    .buttonCenter {
      text-align: center;
    }

    button:hover {
      color: $textPrimary;
      transition: 0.3s;
    }

    button:focus {
      outline: none;
    }
  }
}

.membersImage1-1 {
  position: absolute;
  bottom: 0;
  background-size: contain;
  height: 100%;
  user-select: none;
  z-index: 1;
}

.membersImage1-2 {
  position: absolute;
  bottom: 0;
  background-size: contain;
  height: 100%;
  z-index: 3;
  user-select: none;
}
