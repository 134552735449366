/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.pdfIcon {
  color: #00458b;
  font-size: 18px;
}

.navigationText {
  position: absolute;
  bottom: 2%;
  right: 50%;
  transform: translate(50%);
  user-select: none;
  cursor: pointer;

  h1 {
    text-align: center;
    font-size: 18px;
    color: $textPrimary;
    font-family: roboto-medium;
    user-select: none;
  }

  .imageWrapper {
    transform: translate(0px, -3px);
    text-align: center;
    img {
      user-select: none;
      width: 20px;
      margin: 0 auto;
      fill: $textHighlight !important;
      animation-name: scrollanim;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}

.languageSelector {
  position: absolute;
  transform: translate(0%, 50%);
  bottom: 50%;
  right: 15px;

  .activeLanguage {
    color: #3fd2c7;
  }

  .inactiveLanguage {
    color: $textPrimary;
  }

  h1 {
    text-align: center;
    font-size: 20px;
    color: $textPrimary;
    font-family: roboto-medium;
    margin-bottom: 2px;
    margin-top: 5px;
    user-select: none;
  }

  h1:hover {
    cursor: pointer;
  }
}

@keyframes scrollanim {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, -5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.textBorderLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background-color: $textPrimary;
}

.textBorderRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background-color: $textPrimary;
}

.contentRow1 {
  padding-left: 10px;
  position: relative;
  transform: translate(10%);
  visibility: hidden;
  .rightSide {
    h1 {
      color: $textPrimary;
      font-family: roboto-medium;
      margin-bottom: 5%;
      text-align: center;
      font-size: 30px;
      span {
        color: $textHighlight;
      }
    }
    p {
      font-family: inter-extra;
      font-size: 16px;
      color: $textStandard;
      text-align: center;
    }

    button {
      margin-top: 2%;
      background-color: $textHighlight;
      border: none;
      padding: 15px 40px;
      font-family: roboto-medium;
      font-size: 16px;
      color: $backgroundColor;
      user-select: none;
      transition: 0.3s;
    }

    .buttonCenter {
      text-align: center;
    }

    button:hover {
      color: $textPrimary;
      transition: 0.3s;
    }

    button:focus {
      outline: none;
    }
  }
}

.absoluteImage1-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: contain;
  height: 100%;
  user-select: none;
  z-index: 1;
}

.absoluteImage1-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: contain;
  height: 100%;
  user-select: none;
  z-index: 3;
}

.absoluteImage1-3 {
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: contain;
  height: 100%;
  user-select: none;
  z-index: 1;
}

.absoluteImage1-4 {
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: contain;
  height: 100%;
  user-select: none;
  z-index: 1;
}
