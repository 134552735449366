/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.passwordNotification {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%);
  p {
    text-align: center;
  }
}

.membersRow1 {
  transform: translate(5%);
  padding-left: 10px;
  position: relative;
  z-index: 100;
  visibility: hidden;

  .rightSide {
    h1 {
      color: $textPrimary;
      font-family: roboto-medium;
      text-align: center;
      font-size: 30px;
    }

    span {
      color: $textHighlight;
    }

    p {
      font-family: inter-extra;
      font-size: 16px;
      color: $textStandard;
      text-align: center;
    }

    h4 {
      color: $textPrimary;
      font-family: roboto-medium;
      margin-bottom: 2%;
      user-select: none;
      font-size: 18px;
    }

    input {
      padding: 4px 12px;
      border: 1px solid $textPrimary;
      text-align: center;
    }

    input:focus {
      outline: $textHighlight solid 1px;
      border: 1px solid $textHighlight;
    }

    .showPassword {
      user-select: none;
    }

    button {
      margin-top: 3%;
      background-color: $textPrimary;
      border: none;
      padding: 10px 40px;
      font-family: roboto-medium;
      font-size: 16px;
      color: $backgroundColor;
      user-select: none;
      transition: 0.3s;
    }
    .buttonCenter {
      text-align: center;
    }

    button:hover {
      background-color: $textHighlight;
      transition: 0.3s;
    }

    button:focus {
      outline: none;
    }

    .afterLogin {
      p {
        color: #3fd2c7;
        font-size: 16px;
        font-family: roboto-medium;
      }
    }
  }
}

.wrongPasswordVisible {
  font-family: inter-bold !important;
  color: $textPrimary !important;
  font-size: 16px !important;
  transition: 0.3s;
  opacity: 100%;
}

.wrongPasswordInvisible {
  visibility: hidden !important;
  font-family: inter-bold !important;
  color: $textPrimary !important;
  font-size: 16px !important;
  transition: 0.3s;
  opacity: 0%;
}

.membersLogin1-1 {
  position: absolute;
  bottom: 0;
  background-size: contain;
  height: 100%;
  z-index: 1;
  user-select: none;
}

.membersLogin1-2 {
  position: absolute;
  bottom: 0;
  background-size: contain;
  height: 100%;
  z-index: 3;
  user-select: none;
}
