/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.addBox {
  display: flex;
  flex-direction: row;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  .addBoxLeft {
    width: 40%;
    padding-top: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    h4 {
      padding-left: 15px;
      text-align: left;
      margin-bottom: 10px;
      color: $textPrimary;
    }
    .membersBox {
      -webkit-overflow-scrolling: touch;
      p {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        color: $textStandard;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 0px;
        transition: 0.2s;
        user-select: none;
        border-left: 5px solid white;
      }
      p:hover {
        color: white;
        background-color: $textHighlight;
        transition: 0.2s;
        border-left: 5px solid $textPrimary;
      }

      .addBoxLeftSelected {
        background-color: $textPrimary;
        color: white;
        transition: 0.2s;
        border-left: 5px solid $textHighlight;
      }
    }
  }
  .addBoxRight {
    position: relative;
    border-left: 1px solid #dee2e6;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    .addBoxMainRight {
      height: 100%;
      h3 {
        text-align: center;
        margin-top: 1%;
      }

      .activeButtonBox {
        border: 1px solid #0275d8;
        color: white;
      }

      .inactiveButtonBox {
        border: 1px solid lightgray;
        background-color: white;
        color: #00458b;
      }

      .addBoxButton {
        margin-left: 5px;
        padding: 2px 10px;
        border-radius: 2px;
      }

      .addBox2 {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 20px;
      }

      .addBoxSubmit {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        button {
          padding: 10px;
        }
      }

      .boardButton {
        width: 20px;
        padding: 2px;
        border-radius: 2px;
        margin-right: 5px;
      }

      .boardButton:hover {
        cursor: pointer;
      }

      .boardButtonTrue {
        border: 2px solid limegreen;
        color: limegreen;
      }

      .boardButtonFalse {
        border: 2px solid crimson;
        color: crimson;
      }
    }
  }

  .addBoxNotificationInactive {
    display: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    text-align: center;
    background-color: crimson;
    padding: 20px;
    font-weight: bold;
    color: white;
    z-index: 999;
  }

  .addBoxNotificationActive {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    text-align: center;
    background-color: crimson;
    padding: 20px;
    font-weight: bold;
    color: white;
    z-index: 20;
  }

  .addBoxSuccessInactive {
    display: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    text-align: center;
    background-color: limegreen;
    padding: 20px;
    font-weight: bold;
    color: white;
    z-index: 20;
  }

  .addBoxSuccessActive {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    text-align: center;
    background-color: limegreen;
    padding: 20px;
    font-weight: bold;
    color: white;
    z-index: 20;
  }
}

@media (max-width: 575.98px) {
  .addBoxLeft {
    font-size: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    h4 {
      font-size: 12px;
      padding-left: 5px !important;
    }

    .membersBox {
      p {
        padding: 2px !important;
        border-bottom: 1px solid lightgray;
      }
    }
  }

  .addBox1 {
    justify-content: center;
  }

  .addBoxRight {
    font-size: 12px !important;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .addBoxMainRight {
    h3 {
      font-size: 20px;
    }
    button {
      font-size: 12px;
    }
    input {
      font-size: 12px;
    }
  }

  .nav {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}
