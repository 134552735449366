/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.containerMain {
  display: flex;
  align-self: center;
  margin: 0 auto;
  height: 60%;
  width: 100%;
}

.loginContainerMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  height: 60%;
  width: 100%;

  h5 {
    text-align: center;
    margin-top: 20px;
    color: $textPrimary;
  }

  .loginButtonBox {
    text-align: center;

    input {
      border: 1px solid lightgray;
    }
    button {
      border: 1px solid lightgray;
      background-color: white;
      color: #00458b;
    }
  }

  .warningNotificationActive {
    opacity: 100;
    transition: 0.3s;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    background: crimson;
    padding: 20px;
    color: white;
    font-weight: bold;
  }
  .warningNotificationInactive {
    opacity: 0;
    transition: 0.3s;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    background: crimson;
    padding: 20px;
    color: white;
    font-weight: bold;
  }
}

.adminBox {
  width: 100%;
  .tab-content {
    height: 100%;
    background-color: white;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }
  .tab-pane {
    height: 100%;
  }
}

@media (max-width: 575.98px) {
  .loginContainerMain {
    width: 80%;
    justify-content: flex-start;

    h5 {
      font-size: 18px;
    }
  }
}
