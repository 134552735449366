/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.modalHeader {
  margin: 0 auto;
  h2 {
    font-family: inter-regular;
    font-size: 24px;
    color: $textPrimary;
  }
}

.modalCloseButton {
  position: absolute;
  color: $textStandard;
  top: 10px;
  right: 15px;
  font-size: 20px;
  transition: 0.3s;
  user-select: none;
}

.modalCloseButton:hover {
  cursor: pointer;
  color: rgb(46, 46, 46);
  transition: 0.3s;
  user-select: none;
}

.modalRow {
  margin-top: 2%;
  margin-bottom: 2%;
}

.modalLeft {
  .active {
    background-color: $textPrimary !important;
    border: 1px solid transparent;
    user-select: none;
  }
  .modalLeftItem {
    font-family: inter-regular;
    user-select: none;
    font-size: 14px;
    text-align: left;
  }
}

.modalRight {
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.modalRight::-webkit-scrollbar {
  width: 5px;
}

.modalRight::-webkit-scrollbar-track {
  background: #f1f1f1;
  transition: 0.3s;
}

.modalRight::-webkit-scrollbar-thumb {
  background: rgb(184, 184, 184);
  border-radius: 50px;
}

.modalRight::-webkit-scrollbar-thumb:hover {
  background: rgb(151, 151, 151);
  transition: 0.3s;
}

.modalRightTab {
  padding-right: 40px;
  h6 {
    font-family: inter-bold;
    font-size: 20px;
    color: $textPrimary;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 18px !important;
    color: $textPrimary;
    font-family: inter-bold;
  }
  p {
    font-family: inter-regular;
    font-size: 16px;
    text-align: left;
    color: $textStandard;

    span {
      font-family: inter-bold;
    }
  }

  a {
    font-family: inter-regular;
    font-size: 16px;
    text-align: left;
    color: $textPrimary;
  }
}
