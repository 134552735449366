/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 30px
- buttons: 30px
- headings: 50px
- text: 30px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.sectionBackground {
  background-color: $backgroundColor;
}

#fp-nav {
  a span {
    background-color: $textPrimary !important;
    width: 8px !important;
    height: 8px !important;
  }

  a {
    padding: 5px;
  }

  .active span {
    background-color: $textHighlight !important;
    width: 16px !important;
    height: 16px !important;
  }

  .fp-tooltip.fp-left {
    color: $textPrimary !important;
    font-family: roboto-medium;
    padding-left: 3px;
  }
}

.sectionGeneralStyle {
  overflow: hidden;
}
