/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$boxShadow: rgb(141, 141, 141);
$textHighlight: #3fd2c7;

.contentRow6 {
  transform: translate(-10%);
  z-index: 100;
  position: relative;
  padding-right: 10px;

  .rightSide {
    h1 {
      color: $textPrimary;
      font-family: roboto-medium;
      text-align: center;
      font-size: 30px;
      margin-bottom: 5%;
    }

    span {
      color: $textHighlight;
    }

    .emailHighlight {
      font-family: inter-regular;
      color: $textHighlight;
    }

    p {
      font-family: inter-extra;
      font-size: 16px;
      color: $textStandard;
      text-align: center;
    }
  }
}

.centerLines {
  margin-bottom: 3%;
  text-align: center;
  img {
    width: 10em;
  }
}

.credits {
  font-family: inter-extra !important;
  font-size: 16px !important;
  color: $textStandard !important;
  text-align: center;

  a {
    color: $textPrimary !important;
  }
}

.absoluteImage6-1 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: contain;
  height: 100%;
  user-select: none;
}

.absoluteImage6-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: contain;
  user-select: none;
  height: 100%;
}

.absoluteImage6-3 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: contain;
  user-select: none;
  height: 100%;
}
