/*

general styling

fonts:
- headings: roboto slab bold
- text: inter light

font size:
- navbar: 20px
- buttons: 20px
- headings: 40px
- text: 20px

padding:
- text: 40
- headings: 70

*/

$backgroundColor: #f8f8f8;
$textStandard: #5f5c6d;
$textPrimary: #00458b;
$textSecondary: #99ddff;
$textHighlight: #3fd2c7;

.navbarColumn {
  padding: 0px;
}

.navbar {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1000 !important;
  margin: 0px;
  padding-right: 0px !important;
}

.activeLink {
  font-family: roboto-medium;
  color: $textHighlight !important;
  font-size: 18px;
  transition: 0.3s;
  text-align: center;
  padding: 20px 10px !important;
  user-select: none;
}

.inactiveLink {
  font-family: roboto-medium;
  color: $textPrimary !important;
  font-size: 18px;
  transition: 0.3s;
  padding: 20px 10px !important;
  user-select: none;
}

.infoTab {
  font-family: roboto-medium;
  color: $textPrimary !important;
  font-size: 20px;
  transition: 0.3s;
  padding: 20px 10px !important;
}

.activeLink:hover {
  color: $textHighlight !important;
  transition: 0.3s;
  text-decoration: none !important;
}

.inactiveLink:hover {
  color: $textHighlight !important;
  transition: 0.3s;
  text-decoration: none !important;
}

.navbar-toggler {
  margin-right: 0px !important;
}

.navItems {
  margin-right: 1%;
}

.itemsSpace {
  margin-left: 15px;
}
