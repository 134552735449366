body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
  padding-right: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: inter-extra;
  src: url('../src/fonts/Inter-ExtraLight.woff');
  font-display: swap;
}

@font-face {
  font-family: inter-regular;
  src: url('../src/fonts/Inter-Regular.woff');
  font-display: swap;
}

@font-face {
  font-family: inter-bold;
  src: url('../src/fonts/Inter-Bold.woff');
  font-display: swap;
}

@font-face {
  font-family: roboto-bold;
  src: url('../src/fonts/RobotoSlab-Bold.woff');
  font-display: swap;
}

@font-face {
  font-family: roboto-medium;
  src: url('../src/fonts/RobotoSlab-Medium.woff');
  font-display: swap;
}

@font-face {
  font-family: roboto-extra;
  src: url('../src/fonts/RobotoSlab-ExtraLight.woff');
  font-display: swap;
}
